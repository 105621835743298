import React from "react";
import withTranslation from "../hocs/withTranslation";
import { connect } from "react-redux";

import SubscriptionView from "../components/SubscriptionView";
import {
  loadPaymentMethods,
  saveSubscriptionForUser,
  unsubscribeFromSubscription,
  resetSubscription,
  loadLoyaltyProfile
} from "../store/user/actions";
import { addPaymentMethod } from "../store/order/actions";
import { getDefaultPaymentMethodDetails, getStyles, getRelevantSubscriptionsForUser } from "../store/selectors";
import { get } from "lodash";
import { openAuthView } from "../store/app/actions";


const mapStateToProps = (state, props) => {
  const { user, app } = state;
  return {
    user,
    app,
    appStyles: getStyles(state, props),
    paymentTypeDetails: getDefaultPaymentMethodDetails(state, props),
    subscriptionPolicies: getRelevantSubscriptionsForUser(state, props)
  };
};

const SubscriptionPage = (props) => {
    return <SubscriptionView {...props} />
}

const mapDispatchToProps = (dispatch, props) => {
  const {
    pageContext: { business },
  } = props;

  const currency = get(business, "openChargeCard.price.currency", 0);
  const chargeCardBasePrice = get(business, "openChargeCard.price");

  return {
    loadPaymentMethods: (paymentTypeIdentifier) =>
      dispatch(loadPaymentMethods(paymentTypeIdentifier, currency)),
    saveSubscriptionForUser : (subscriptionParams) => 
      dispatch(saveSubscriptionForUser(subscriptionParams)),
      unsubscribeFromSubscription : (subscriptionParams) => dispatch(unsubscribeFromSubscription(subscriptionParams)),
    openAuthView: (loginType) => dispatch(openAuthView(loginType)),
    resetSubscription: () => dispatch(resetSubscription()),
    loadLoyaltyProfile: () => dispatch(loadLoyaltyProfile()),
    addPaymentMethod: (params) => dispatch(addPaymentMethod({ ...params, currency }))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(SubscriptionPage));
