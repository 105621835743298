// extracted by mini-css-extract-plugin
export var BackToHomeDesktop = "index-module--BackToHomeDesktop--53fbc";
export var BackToHomeMobile = "index-module--BackToHomeMobile--531c5";
export var DesktopGutters = "index-module--DesktopGutters--71cb9";
export var ErrorBlock = "index-module--ErrorBlock--f65bb";
export var Loader = "index-module--Loader--66e6c";
export var PreviewButton = "index-module--PreviewButton--5f3ea";
export var SendButton = "index-module--SendButton--d1df3";
export var Subscription = "index-module--Subscription--28fb6";
export var SubscriptionBottomRow = "index-module--SubscriptionBottomRow--fa74a";
export var SubscriptionListItem = "index-module--SubscriptionListItem--d509f";
export var SubscriptionText = "index-module--SubscriptionText--8a892";
export var SubscriptionTopRows = "index-module--SubscriptionTopRows--0e548";
export var Title = "index-module--Title--e8f81";